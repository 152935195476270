<template>
	<div class="pui-form">
		<portauthority-modals :modelName="modelName"></portauthority-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="modelName" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.portauthority.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#statusvisittab'" v-if="hasVisitStatusFunctionality">{{ $t('form.portauthority.statusvisittab') }}</v-tab>
				<v-tab :key="2" :href="'#statusstoptab'" v-if="hasStopStatusFunctionality">{{ $t('form.portauthority.statusstoptab') }}</v-tab>
				<v-tab :key="3" :href="'#configurationvariabletab'" v-if="!isCreatingElement && !isModalDialog">{{
					$t('form.portauthority.configurationvariabletab')
				}}</v-tab>
				<v-tab :key="4" :href="'#visittransitiontab'" v-if="!isCreatingElement">{{ $t('form.portauthority.visittransitiontab') }}</v-tab>
				<v-tab :key="5" :href="'#stoptransitiontab'" v-if="!isCreatingElement">{{ $t('form.portauthority.stoptransitiontab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="modelName">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<pui-field-set :title="$t('form.portauthority.formtitle')">
								<v-row dense>
									<v-col cols="4">
										<pui-text-field
											:id="`name-portauthority`"
											v-model="model.name"
											:label="$t('portauthority.name')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-col>
									<v-col cols="3">
										<pui-text-field
											:id="`portauthoritycode-portauthority`"
											v-model="model.portauthoritycode"
											:label="$t('portauthority.portauthoritycode')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-col>
									<v-col cols="4">
										<pui-select
											:id="`countryid-portauthority`"
											:attach="`countryid-portauthority`"
											:label="$t('portauthority.countryid')"
											:disabled="formDisabled"
											required
											toplabel
											clearable
											modelName="country"
											v-model="model"
											reactive
											:itemsToSelect="countryidItemsToSelect"
											:modelFormMapping="{ code: 'countryid' }"
											itemValue="code"
											itemText="name"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="3">
										<pui-number-field
											:id="`cartooficialsrid-portauthority`"
											v-model="model.cartooficialsrid"
											:label="$t('portauthority.cartooficialsrid')"
											:disabled="formDisabled"
											required
											toplabel
											integer
											min="0"
											max="99999"
										></pui-number-field>
									</v-col>
									<v-col cols="4">
										<pui-text-field
											:id="`timezone-portauthority`"
											v-model="model.timezone"
											:label="$t('portauthority.timezone')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="50"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'statusvisittab'" v-if="hasVisitStatusFunctionality">
					<v-row class="pui-form-layout">
						<v-col>
							<pui-multi-select
								itemValue="visitstatuscode"
								itemText="visitstatusname"
								v-model="model.visitstatus"
								:items="allVisitStatus"
								:itemsToSelect="model.visitstatus"
								:disabled="formDisabled"
								reactive
							>
							</pui-multi-select>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="2" lazy :value="'statusstoptab'" v-if="hasStopStatusFunctionality">
					<v-row class="pui-form-layout">
						<v-col>
							<pui-multi-select
								itemValue="stopstatuscode"
								itemText="stopstatusname"
								v-model="model.stopstatus"
								:items="allStopStatus"
								:itemsToSelect="model.stopstatus"
								:disabled="formDisabled"
								reactive
							>
							</pui-multi-select>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="3" lazy :value="'configurationvariabletab'" v-if="!isCreatingElement && !isModalDialog">
					<pui-master-detail
						componentName="portauthorityvariablegrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'port_authority_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="4" lazy :value="'visittransitiontab'" v-if="!isCreatingElement">
					<pui-master-detail
						componentName="visitstatustransitiongrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'port_authority_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="5" lazy :value="'stoptransitiontab'" v-if="!isCreatingElement">
					<pui-master-detail
						componentName="stopstatustransitiongrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'port_authority_id' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import portauthorityActions from './PortauthorityActions';
import portauthorityModals from './PortauthorityModals.vue';

export default {
	name: 'portauthority-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'portauthority-modals': portauthorityModals
	},
	data() {
		return {
			modelName: 'portauthority',
			actions: portauthorityActions.formActions,
			allVisitStatus: [],
			allStopStatus: []
		};
	},
	methods: {
		afterGetData() {
			this.getAllVisitStatus();
			this.getAllStopStatus();
		},
		async getAllVisitStatus() {
			const opts = {
				model: 'visitstatus'
			};
			const resp = await this.$puiRequests.postRequest('/puisearch', opts);
			if (resp) {
				this.allVisitStatus = resp.data.data;
			}
		},
		async getAllStopStatus() {
			const opts = {
				model: 'stopstatus'
			};
			const resp = await this.$puiRequests.postRequest('/puisearch', opts);
			if (resp) {
				this.allStopStatus = resp.data.data;
			}
		}
	},
	computed: {
		countryidItemsToSelect() {
			return [{ code: this.model.countryid }];
		},
		hasVisitStatusFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_VISITSTATUS');
		},
		hasStopStatusFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_STOPSTATUS');
		}
	},
	created() {}
};
</script>
